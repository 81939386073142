<template>
  <div class="landing">
    <div v-show="!this.$isMobile" class="banner">
      <div class="Carousel">
        <el-carousel arrow="always">
          <el-carousel-item v-for="(item, index) in CarouselData" :key="index">
            <img :src="item.img" alt="" srcset="" @click="handleTo(item)" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="search">
        <div class="contain-box">
          <h2>{{ $t("Landing.Recharge") }}</h2>
          <h3>{{ $t("Landing.chooseGame") }}</h3>
          <el-autocomplete
            class="search-input"
            v-model="state"
            :fetch-suggestions="querySearch"
            :placeholder="$t('Landing.enterGameName')"
            @select="handleSelect"
          >
            <i class="el-icon-search el-input__icon" slot="prefix"> </i>
            <template slot-scope="{ item }">
              <div class="name" @click="handleClick(item)">
                {{ item.game_name }}
              </div>
            </template>
          </el-autocomplete>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="list-game-title custom-radio-button filter">
        <h3 class="home-title">{{ $t("Landing.gameList") }}</h3>
        <div>
          <div
            class="filter-btn"
            v-for="(item, index) in filterBtn"
            :key="index"
            :class="{ selected: selectedButton === index }"
            @click="filterCheck(item, index)"
          >
            <span>{{ item }}</span>
          </div>
        </div>
      </div>
      <div>
        <div class="list-card">
          <div
            class="animationCustom"
            v-for="(item, index) in showGame"
            :key="index"
            @click="handleClick(item)"
          >
            <div class="el-card box-card is-never-shadow">
              <div class="el-card__body" style="padding: 0px; cursor: pointer">
                <div class="" :title="item.game_name">
                  <h4 id="app-name-volamnh" custom-value="volamnh">
                    {{ item.game_name }}
                  </h4>
                  <div
                    class="thumb-img"
                    :style="{ backgroundImage: `url(${item.game_icon})` }"
                  ></div>
                </div>
                <button
                  type="button"
                  class="el-button el-button--primary el-button--small is-round"
                  id="app-button-volamnh"
                  custom-value="volamnh"
                >
                  {{ $t("Landing.enter") }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="load-more" v-show="moreShow">
          <div class="load-more__content" @click="handleMore">
            {{ $t("Landing.more") }}
          </div>
        </div> -->
      </div>
    </div>
    <div class="vng-benefit">
      <div class="benefit-header">
        {{ $t("Landing.problem") }}
      </div>
      <div class="benefit-list">
        <div class="benefit" v-for="(item, index) in benefit" :key="index">
          <div>
            <img alt="" :src="item.src" />
          </div>
          <div>
            <p class="benefit-header">{{ item.name }}</p>
            <div>
              <div ref="content" class="read-more-p">
                <div ref="readMore" class="read-more">{{ item.readMore }}</div>
              </div>
              <div
                @click="handleReadMore(item, index)"
                v-show="item.lookMore"
                class="view-more"
              >
                {{ item.check ? $t("Landing.more") : $t("Landing.close") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ShowInfo ref="showInfo" />
  </div>
</template>

<script>
import ShowInfo from "./components/showInfo.vue";
export default {
  name: "",
  mixins: [],
  components: { ShowInfo },
  props: {},
  data() {
    return {
      state: "",
      pathName: "",
      // CarouselData: [],
      selectedButton: 0, //判断选中的按钮索引
      moreNum: 1, //查看更多
      moreShow: true, //当数量相同时，查看更多消失
      showGame: [], //展示在页面的游戏
      IOSGame: [], //IOS游戏
      AndroidGame: [], //安卓游戏
      // allGame: [], //获取到的全部游戏
      // benefit:
    };
  },
  computed: {
    allGame() {
      return this.$store.state.gameList;
    },
    CarouselData() {
      return this.$store.state.bannerList;
    },
    benefit(){
      return [
        {
          name: this.$t("Landing.answer1_title"),
          src: require("@/assets/img/vip/limit_activity.png"),
          readMore: this.$t("Landing.answer1_dec"),
        },
        {
          name: this.$t("Landing.answer2_title"),
          src: require("@/assets/img/vip/vip.png"),
          readMore: this.$t("Landing.answer2_dec"),
        },
        {
          name: this.$t("Landing.answer3_title"),
          src: require("@/assets/img/vip/chat.png"),
          readMore: this.$t("Landing.answer3_dec"),
        },
        {
          name: this.$t("Landing.answer4_title"),
          src: require("@/assets/img/vip/promotional_activities.png"),
          readMore: this.$t("Landing.answer4_dec"),
        },
        {
          name: this.$t("Landing.answer5_title"),
          src: require("@/assets/img/vip/more_pay.png"),
          readMore: this.$t("Landing.answer5_dec"),
        },
      ]
    },
    filterBtn(){
      return [this.$t("Landing.allGame"), "Game IOS", "Game Android"] //切换游戏按钮
    }
  },
  watch: {
    allGame: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.getGame();
    },
    handleTo(item) {
      switch (Number(item.type)) {
        case 1:
          this.showData(item);
          break;
        case 2:
          window.location.href = item.url;
          break;
        case 3:
          break;
      }
    },
    //打开弹框显示
    showData(item) {
      this.$refs.showInfo.dialogFormVisible = true;
      this.$refs.showInfo.info = item;
    },
    getGame() {
      //获取游戏数据
      if (!this.allGame) {
        return;
      }
      this.showGame = this.allGame.slice(0, this.moreNum * 6);
      this.IOSGame = this.allGame.filter((item) => item.platform === "2");
      this.AndroidGame = this.allGame.filter((item) => item.platform === "1");
      this.benefit.forEach((item, index) => {
        //     //当两个高度相同时，不需要展示更多
        this.$refs.content[index].style.overflow = "visible";
        this.$refs.content[index].style.height = "100%";
        item.lookMore = false;
      });
      // if (this.$isMobile) {
      //   //判断是否超出字数
      //   this.benefit.forEach((item, index) => {
      //     //当两个高度相同时，不需要展示更多
      //     if (
      //       this.$refs.content[index].offsetHeight ===
      //       this.$refs.readMore[index].offsetHeight
      //     ) {
      //       item.lookMore = false;
      //     } else {
      //       //当两个高度不同时
      //       item.lookMore = true;
      //       item.check = true;
      //     }
      //   });
      // } else {
      //   this.benefit.forEach((item, index) => {
      //     //当两个高度相同时，不需要展示更多
      //     this.$refs.content[index].style.overflow = "visible";
      //     this.$refs.content[index].style.height = "100%";
      //     item.lookMore = false;
      //   });
      // }
    },
    querySearch(queryString, cb) {
      var allGame = this.allGame;
      var results = queryString
        ? allGame.filter(this.createFilter(queryString))
        : allGame;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.game_name
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    filterCheck(item, index) {
      this.moreNum = 1; //切换按钮的时候重新赋值
      this.moreShow = true; //切换按钮时更新查看更多的状态
      this.selectedButton = index;
      if (index === 0) {
        //展示全部数据
        if (!this.allGame) {
          return;
        }
        this.showGame = this.allGame.slice(0, this.moreNum * 6);
      }
      if (index === 1) {
        //展示IOS数据
        if (!this.IOSGame) {
          return;
        }
        this.showGame = this.IOSGame.slice(0, this.moreNum * 6);
      }
      if (index === 2) {
        //展示Android数据
        if (!this.AndroidGame) {
          return;
        }
        this.showGame = this.AndroidGame.slice(0, this.moreNum * 6);
      }
    },
    handleMore() {
      if (this.selectedButton === 0) {
        //添加6条全部数据
        this.allGame.length === this.showGame.length ? "" : this.moreNum++;
        this.showGame = this.allGame
      }
      if (this.selectedButton === 1) {
        //添加6条IOS数据
        this.IOSGame.length === this.showGame.length ? "" : this.moreNum++;
        this.showGame = this.IOSGame
      }
      if (this.selectedButton === 2) {
        //添加6条Android数据
        this.AndroidGame.length === this.showGame.length ? "" : this.moreNum++;
        this.showGame = this.AndroidGame
      }
    },
    handleReadMore(item, index) {
      item.check
        ? (this.$refs.content[index].style.height =
            this.$refs.readMore[index].offsetHeight + "px")
        : (this.$refs.content[index].style.height = "48px");
      item.check = !item.check;
      this.$forceUpdate();
    },
    handleSelect(item) {
      this.state = item.game_name;
    },
    handleClick(e) {
      this.$router.push({
        path: "/login",
        query: {
          g: e.game_id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
$color-theme: rgb(17, 136, 255);
$color-theme-rgb: 17, 136, 255;
@import "./css/pc.scss";
@import "./css/phone.scss";
</style>
